import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import useStyle from './styled';

const LoginModal = ({ open }) => {
  const classes = useStyle();
  return (
    <Snackbar className={classes.modal} open={open}>
      <Alert variant="filled" severity="error">
        Este usuario no ha sido registrado, Por favor contactar con el
        administrador
      </Alert>
    </Snackbar>
  );
};

LoginModal.propTypes = {
  status: PropTypes.bool,
};

export default LoginModal;
