import { Typography } from '@material-ui/core';
import React, { useState } from 'react';
import useStyles from './styled';
import QRCodeReader from '../QRCodeReader';
import { post } from '../../utils/fetch';
import SimpleModal from '../SimpleModal';
import { Button } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import Alert from '@mui/material/Alert';
import useLocationHook from '../../hooks/useLocation';

function VerificationUser() {
  const classes = useStyles();
  const [currentQr, setCurrentQr] = useState(false);
  const [previewQr, setPreviewQr] = useState(false);
  const [open, setIsOpen] = useState();
  const [showMessage, setshowMessage] = useState(false);
  const [realTimes, setRealTimes] = useState(false);
  const [showFirstScanner, setShowFirstScanner] = useState(true);
  const [showSecondScanner, setShowSecondScanner] = useState(true);
  const [resetBack, setResetBack] = useState(true);
  const { latitude, longitude } = useLocationHook();
  const [verification, setVerification] = useState({
    data: '',
    loading: false,
  });

  const getStatus = async qrCode => {
    let body = {};
    setIsOpen(qrCode && true);
    setVerification({ data: '', loading: true });

    const { data, valid } = await post(`/api/citizenId/`, {
      qrCode,
    }).then(res => res.json());

    if (!showFirstScanner) {
      if (previewQr !== currentQr && !showFirstScanner) {
        setPreviewQr(data);
      }
      setCurrentQr(data);
    }

    if (valid) {
      body = {
        cedula: data,
        latitud: latitude,
        longitud: longitude,
        url: '',
      };
    } else {
      body = {
        cedula: '',
        latitud: latitude,
        longitud: longitude,
        url: qrCode,
      };
    }

    setshowMessage(true);
    await post(`/api/validateCitizen/`, body)
      .then(res => res.json())
      .then(({ data }) => {
        setVerification(Object.assign(data, { loading: false }));
        setshowMessage(false);
      });

    setTimeout(() => {
      setIsOpen(false);
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!qrCode) {
        setVerification({ data: undefined, loading: false });
      }
    }, 1500);
  };

  function reset() {
    setShowFirstScanner(true);
    setShowSecondScanner(true);
    setResetBack(!resetBack);
  }

  return (
    <div className={classes.container}>
      {!showSecondScanner && showMessage ? (
        <Alert variant="filled" severity="info" className={classes.message}>
          Validando identificación
        </Alert>
      ) : (
        <Typography variant="h4" gutterBottom className={classes.title}>
          Inicio
        </Typography>
      )}
      {!showFirstScanner && showMessage && currentQr !== previewQr && (
        <Alert variant="filled" severity="info" className={classes.message}>
          Validando identificación
        </Alert>
      )}
      {showFirstScanner && (
        <div
          onClick={() => {
            setShowSecondScanner(false);
            setRealTimes(false);
          }}
        >
          <QRCodeReader
            onScan={getStatus}
            setIsCameraOpen={setIsOpen}
            verification={verification}
            label={'Verificar Datos personales'}
            realTime={realTimes}
            resetBack={resetBack}
          />
        </div>
      )}
      {showSecondScanner && (
        <div
          onClick={() => {
            setShowFirstScanner(false);
            setRealTimes(true);
          }}
          style={{
            marginTop: 15,
          }}
        >
          <QRCodeReader
            loading={verification.loading}
            onScan={getStatus}
            setIsCameraOpen={setIsOpen}
            verification={verification}
            label={'Verificar en tiempo real'}
            realTime={realTimes}
            resetBack={resetBack}
          />
        </div>
      )}
      {Boolean(open) && (
        <>
          <SimpleModal
            notificationType={showFirstScanner}
            isOpen={open}
            status={verification && verification.statusCode}
          />
        </>
      )}
      {showFirstScanner && showSecondScanner ? (
        <Button
          style={{ margin: 'auto', marginTop: '2em' }}
          onClick={() => window.location.reload()}
        >
          <ArrowBack style={{ color: '#264B81' }} />
          <Typography
            style={{
              color: '#264B81',
              fontSize: '14px',
              fontWeight: '600',
            }}
          >
            Ir a inicio
          </Typography>
        </Button>
      ) : (
        <Button
          style={{ margin: 'auto', marginTop: '2em' }}
          onClick={() => reset()}
        >
          <ArrowBack style={{ color: '#264B81' }} />
          <Typography
            style={{
              color: '#264B81',
              fontSize: '14px',
              fontWeight: '600',
            }}
          >
            Ir a inicio
          </Typography>
        </Button>
      )}
    </div>
  );
}
export default VerificationUser;
