import React, { useState } from 'react';
import { Card, Typography } from '@material-ui/core';
import useStyles from './styled';
import { catchError } from '../../catchErrors/ErrorComponent';
import QRCodeReader from '../../components/QRCodeReader';
import VerificationUser from '../../components/VerificationUser';
import img from '../../../src/tres-logos-fn-azul-short2.svg';
import { get, post } from '../../utils/fetch';
import LoginModal from '../../components/LoginModal';
import { useLocation } from '../../context/LocationContext';
import { Alert } from '@material-ui/lab';

function VerificationPage({ history }) {
  const classes = useStyles();
  const [login, setLogin] = useState();
  const { isLocactionActive } = useLocation();

  const getStatus = async qrCode => {
    const { data } = await post(`/api/citizenId/`, { qrCode }).then(res =>
      res.json()
    );
    // setLogin(true); Este useState es para hacer login en la aplicacion y pasar al otro apartado.
    await get(`/api/validateUser/${data}`)
      .then(res => res.json())
      .then(({ valid }) => {
        setLogin(valid);
      });
  };

  return (
    <div>
      <Card className={classes.container}>
        <img src={img} width={'100%'} alt="img" />
        {!login ? (
          <>
            <Typography variant="h4" gutterBottom className={classes.title}>
              Acceder
            </Typography>
            <QRCodeReader onScan={getStatus} label={'cédula'} />
          </>
        ) : (
          <div style={{ display: 'block', width: '100%' }}>
            <VerificationUser />
          </div>
        )}
        <LoginModal
          open={login === false ? setTimeout(() => setLogin(null), 2500) : null}
        />
        {!isLocactionActive && (
          <Alert severity="warning" className={classes.locationMessage}>
            Si desea acceder al sistema tiene que permitir activar la Ubicación
            para este sitio web
          </Alert>
        )}
      </Card>
    </div>
  );
}
export default catchError(VerificationPage);
