import { useState, useEffect } from 'react';

export default function useLocation() {
  const [errorMessage, setErrorMessage] = useState('');
  const [currentLocation, setCurrentLocation] = useState(null);

  const onChange = ({ coords }) => {
    setCurrentLocation({
      latitude: coords.latitude,
      longitude: coords.longitude,
    });
  };
  const error = err => {
    setErrorMessage(err);
  };
  useEffect(() => {
    const geoLocation = navigator.geolocation;
    if (geoLocation) {
      const positionId = geoLocation.watchPosition(onChange, error);
      return () => geoLocation.clearWatch(positionId);
    } else {
      setErrorMessage('Geolocation is not supported by this browser.');
      return;
    }
  }, []);

  return { ...currentLocation, errorMessage };
}
