import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  button: {
    position: 'absolute',
    left: '75%',
    top: '55%',
  },
  modal: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  paper: {
    height: '30%',
    margin: 'auto',
    padding: theme.spacing(2, 4, 3),
    position: 'relative',
    width: `20%`,
    [theme.breakpoints.down(720)]: {
      width: `50%`,
    },
  },
  paper2: {
    backgroundColor: 'transparent',
    height: '30%',
    margin: 'auto',
    position: 'relative',
    width: `25%`,
  },
  backgroundRed: {
    display: 'flex',
    padding: '5%',
    margin: 'auto',
    position: 'relative',
    width: `25%`,
    backgroundColor: '#BD5252',
  },
  backgroundYellow: {
    display: 'flex',
    padding: '5%',
    margin: 'auto',
    position: 'relative',
    width: `25%`,
    backgroundColor: '#F7E168',
  },
  body: {
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontWeight: 'bold',
    textAlign: 'center',
    margin: 'auto',
  },
  vaccineAlert: {
    display: 'flex',
    width: '70%',
    height: '50px',
    textAlign: 'center',
    textTransform: 'Uppercase',
    justifyContent: 'center',
    alignItems: 'center',
  },
  alerts: {
    padding: '5px',
    display: 'flex',
    justifyContent: 'center',
  },
}));
