import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  container: {
    boxShadow: '1px 1px 8px hsla(0, 0%, 40%, 0.3)',
    display: 'flex',
    flexWrap: 'Wrap',
    margin: '0 auto 45vh',
    maxWidth: 400,
    maxHeight: 'auto',
    minWidth: 50,
    padding: 30,
    backgroundSize: 'cover',
    borderRadius: 10,
    textAlign: 'center',
    [theme.breakpoints.down(400)]: {
      maxWidth: 'auto',
    },
  },
  title: {
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '10%',
    marginTop: '10%',
    textAlign: 'center',
    width: '90%',
    margin: 'auto',
  },
}));
