const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
export {
  REACT_APP_API_URL
};


export const get = url => {
  const bodyOpts = {
    method: 'GET',
  };

  return fetch(REACT_APP_API_URL ? `${REACT_APP_API_URL}${url}`: url, bodyOpts);
};

export const post = (url, body = {}) => {
  const bodyOpts = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };

  return fetch(REACT_APP_API_URL ? `${REACT_APP_API_URL}${url}`: url, bodyOpts);
};
