import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@material-ui/core';
import useStyles from './styled';
import vaccine from '../../icons/vaccine-check.png';
import vaccineWarning from '../../icons/vaccine-warning.png';
import vaccineErr from '../../icons/vaccine-err.png';
import Alert from '@mui/material/Alert';
import ReactAudioPlayer from 'react-audio-player';
import audio from '../../sound/2.wav';

function getModalStyle() {
  return {
    top: `50%`,
    left: `0%`,
    transform: `translate(0, -50%)`,
  };
}

export default function SimpleModal({
  isOpen,
  onClose,
  status,
  notificationType,
}) {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  const alert = status => {
    switch (status) {
      case 200:
        return (
          <>
            <img
              width="100%"
              style={{ margin: 'auto' }}
              src={vaccine}
              alt="img"
            />
            <ReactAudioPlayer
              src={audio}
              style={{ display: 'none' }}
              autoPlay
              controls
            />
          </>
        );
      case 401:
        return (
          <img
            width="100%"
            style={{ margin: 'auto' }}
            src={vaccineWarning}
            alt="img"
          />
        );
      default:
        return (
          <img
            width="100%"
            style={{ margin: 'auto' }}
            src={vaccineErr}
            alt="img"
          />
        );
    }
  };

  if (!notificationType) {
    return (
      <div>
        {status && (
          <div className={classes.alerts}>
            {status === 200 ? (
              <>
                <Alert
                  className={classes.vaccineAlert}
                  variant="filled"
                  severity="success"
                >
                  Vacunado
                </Alert>
                <ReactAudioPlayer
                  src={audio}
                  style={{ display: 'none' }}
                  autoPlay
                  controls
                />
              </>
            ) : status === 401 ? (
              <Alert
                className={classes.vaccineAlert}
                variant="filled"
                severity="warning"
              >
                Sin concluir vacunación
              </Alert>
            ) : (
              <Alert
                className={classes.vaccineAlert}
                variant="filled"
                severity="error"
              >
                Sin vacunación
              </Alert>
            )}
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      {status && (
        <Modal
          className={classes.fmodal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={isOpen}
          onClose={onClose}
        >
          <div style={modalStyle} className={classes.paper}>
            {alert(status)}
          </div>
        </Modal>
      )}
    </div>
  );
}

SimpleModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  body: PropTypes.string,
  notificationType: PropTypes.bool,
};

SimpleModal.defaultProps = {
  body: '',
};
