import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  button: {
    border: 'none',
    borderRadius: 50,
    color: '#fff',
    display: 'flex',
    fontSize: 20,
    fontWeight: 400,
    margin: 'auto',
    width: '100%',
    [theme.breakpoints.down(720)]: {
      fontSize: 10,
      fontWeight: 200,
      height: 50,
    },
    [theme.breakpoints.down(430)]: {
      fontSize: 10,
      fontWeight: 200,
      height: 40,
      width: '75%',
    },
    [theme.breakpoints.down(430)]: {
      fontSize: 8,
      fontWeight: 200,
      height: 40,
      width: '96%',
    },
  },
  locationMessage: {
    with: '100%',
    marginTop: '11px',
  },

  buttonHalfSize: {
    border: 'none',
    borderRadius: 50,
    color: '#fff',
    display: 'flex',
    fontSize: 20,
    fontWeight: 300,
    margin: 'auto',
    width: '100%',
    [theme.breakpoints.down(720)]: {
      fontSize: 10,
      fontWeight: 200,
      height: 50,
    },
    [theme.breakpoints.down(430)]: {
      fontSize: 10,
      fontWeight: 200,
      height: 40,
    },
  },
  closeScanner: {
    float: 'right',
    zIndex: 10,
    color: '#00205C',
  },
  icon: {
    width: 45,
    [theme.breakpoints.down(720)]: {
      height: 35,
      width: 35,
    },
    [theme.breakpoints.down(430)]: {
      height: 30,
      width: 30,
    },
  },
  iconHallfSize: {
    float: 'left',
    width: 30,
    [theme.breakpoints.down(720)]: {
      height: 25,
      width: 25,
    },
    [theme.breakpoints.down(430)]: {
      height: 22,
      width: 22,
    },
  },
  labelHalffSize: {
    fontWeight: 'bold',
    [theme.breakpoints.down(720)]: {
      fontSize: 14,
    },
    [theme.breakpoints.down(430)]: {
      fontSize: 11,
    },
    [theme.breakpoints.down(376)]: {
      fontSize: 9,
    },
  },
  label: {
    fontWeight: 'bold',
  },
  reader: {
    '& > section': {
      overflow: 'unset !important',
    },
  },
}));
