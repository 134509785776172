import React, { useState } from 'react';
// Google Analytics
import { ThemeProvider } from '@material-ui/core';
import { Route, Switch } from 'react-router-dom';
import { LocationProvider } from './context/LocationContext';
import AppContext from './context/AppContext';
import InternetNotification from './components/internetNotification';
import theme from './theme';
import './styles/App.css';
import NotFoundPage from './route/NotFountPage';
import VerificationPage from './route/VerificationPage';

function App() {
  const [connection, setConnection] = useState(window.navigator.onLine);
  const [locationState, setLocationState] = useState(undefined);

  function updateIndicator() {
    setConnection(window.navigator.onLine);
    setLocationState({ latitude: null, longitude: null });
  }

  window.addEventListener('online', updateIndicator);
  window.addEventListener('offline', updateIndicator);

  return (
    <>
      <ThemeProvider theme={theme}>
        <InternetNotification status={connection} />
        <LocationProvider>
          <AppContext.Provider value={{ locationState }}>
            <Switch>
              <Route exact path="/" component={VerificationPage} />
              <Route path="*" component={NotFoundPage} />
            </Switch>
          </AppContext.Provider>
        </LocationProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
