import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import QrReader from 'react-qr-reader';
import { Button, IconButton, Typography, Tooltip } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useLocation } from '../../context/LocationContext';
import whiteIcon from '../../icons/Iconos_Check_Point_App_Blanco-07.svg';
import grayIcon from '../../icons/Iconos_Check_Point_App_Gris-07.svg';

import useStyles from './styled';

function QRCodeReader({
  onScan,
  onError,
  isScanned,
  label,
  sizeButton,
  realTime,
  resetBack,
  passport,
  loading,
}) {
  const classes = useStyles();
  const { isLocactionActive } = useLocation();
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const toolTipAllowed = label === 'cédula';

  const handleScan = async data => {
    if (!data) return;
    await onScan(data);
    if (realTime) {
      setIsCameraOpen(true);
    } else {
      setIsCameraOpen(false);
    }
  };

  useEffect(() => {
    setIsCameraOpen(false);
  }, [resetBack]);

  return (
    <div style={{ width: '100%' }}>
      {isCameraOpen ? (
        <>
          {toolTipAllowed ? (
            <Tooltip title="Cerrar Escaner" className={classes.closeScanner}>
              <IconButton
                color="secondary"
                onClick={() => {
                  setIsCameraOpen(false);
                }}
              >
                <HighlightOffIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          ) : (
            ''
          )}
          <QrReader
            delay={2000}
            onError={onError}
            onScan={handleScan}
            style={{ width: '100%', overflow: 'unset' }}
            className={classes.reader}
          />
        </>
      ) : (
        ''
      )}
      {!isCameraOpen && (
        <Button
          variant="contained"
          className={
            sizeButton === 'fullWidth'
              ? `${classes.button} escan-qr`
              : classes.buttonHalfSize
          }
          disabled={ !isLocactionActive }
          startIcon={
            <img
              className={
                sizeButton === 'fullWidth'
                  ? classes.icon
                  : classes.iconHallfSize
              }
              src={isCameraOpen ? grayIcon : whiteIcon}
              alt="Scanner icon"
            />
          }
          style={{
            backgroundColor: isScanned ? '#17A207' : '#00205C',
            borderRadius: 5,
            height: 55,
          }}
          onClick={() => {
            label === 'Escanear Cédula' &&
              window.scrollTo({ top: 0, behavior: 'smooth' });
            setIsCameraOpen(true);
          }}
        >
          <div style={{ width: '100%', justifyContent: 'center' }}>
            <Typography
              className={
                sizeButton === 'fullWidth'
                  ? classes.label
                  : classes.labelHalffSize
              }
            >
              {label}
            </Typography>
          </div>
        </Button>
      )}
    </div>
  );
}

QRCodeReader.propTypes = {
  isCameraOpen: PropTypes.bool,
  isScanned: PropTypes.bool,
  label: PropTypes.string,
  sizeButton: PropTypes.string,
  realTime: PropTypes.bool,
  resetBack: PropTypes.bool,
};

QRCodeReader.defaultProps = {
  isCameraOpen: false,
  isScanned: false,
  label: 'Escanear QR',
  sizeButton: 'fullWidth',
};

export default QRCodeReader;
