import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  container: {
    margin: 'auto',
    // height:'500px'
  },
  title: {
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '10%',
    marginTop: '10%',
    textAlign: 'center',
    width: '132px',
    margin: 'auto',
  },
  scannerContainer: {
    margin: '2em',
  },
  message: {
    fontSize: '17px',
    marginBottom: '10%',
    marginTop: '10%',
    margin: 'auto',
  },
  button: {
    backgroundColor: '#264B81',
    border: 'none',
    borderRadius: 50,
    color: '#fff',
    display: 'flex',
    fontSize: 20,
    fontWeight: 400,
    margin: 'auto',
    width: '100%',
    [theme.breakpoints.down(720)]: {
      fontSize: 10,
      fontWeight: 200,
      height: 50,
    },
    [theme.breakpoints.down(430)]: {
      fontSize: 10,
      fontWeight: 200,
      height: 40,
      width: '75%',
    },
  },
  icon: {
    width: 50,
    [theme.breakpoints.down(720)]: {
      height: 35,
      width: 35,
    },
    [theme.breakpoints.down(430)]: {
      height: 30,
      width: 30,
    },
  },
  closeScaner: {
    left: '42%',
    top: 50,
    zIndex: 10,
    [theme.breakpoints.up(958)]: {
      left: '40%',
    },
  },
}));
